export type AppsConfiguration = {
  appsInternalHandlerLambdaArn: string;
  tenantId?: string;
};

export type GetConfigByHostRequest = {
  host: string;
  appId: string;
};

export { AppConfig, DefaultConfiguration, AppEvent } from '@hypercharge/apps/lib/types';
