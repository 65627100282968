import {
  Definition,
  ItemMeta,
  Property,
  PropertyTypeInfo,
  Section
} from '@hypercharge/cms/lib/types';
import { DbPurpose } from '../../types';

export {
  Definition,
  ItemMeta,
  Property,
  PropertyTypes,
  PropertyTypeInfo,
  Section
} from '@hypercharge/cms/lib/types';

export type GetItemMetaRequest = {
  tenantId?: string;
  definitionId: string;
  dbPurpose?: DbPurpose;
};

export type GetAllItemMetaRequest = {
  tenantId?: string;
  handler?: string;
  languageCode?: string;
  dbPurpose?: DbPurpose;
};

export type SaveItemMetaRequest = {
  tenantId?: string;
  itemMeta: ItemMeta;
};

export type GetDefinitionRequest = {
  tenantId?: string;
  definitionId: string;
  principalGroups?: string[];
  dbPurpose?: DbPurpose;
};

export type SaveDefinitionRequest = {
  tenantId?: string;
  definition: Definition;
};

export type DeleteDefinitionRequest = {
  tenantId?: string;
  definitionId: string;
  force?: boolean;
};

export type CreateItemMeta = Omit<
  ItemMeta,
  'viewGroups' | 'createGroups' | 'deleteGroups' | 'handler'
> & {
  viewGroups?: string[];
  createGroups?: string[];
  deleteGroups?: string[];
  handler?: string;
};

export type CreateSection = Omit<Section, 'properties'> & { properties: CreateProperty[] };

export type CreateProperty = Omit<Property, 'readGroups' | 'writeGroups'> & {
  readGroups?: string[];
  writeGroups?: string[];
} & PropertyTypeInfo;

export type CreateDefinitionRequest = {
  tenantId: string;
  itemMeta: CreateItemMeta;
  sections?: CreateSection[];
};

export type AddSectionsToDefinitionRequest = {
  tenantId: string;
  definition: Definition;
  sections: CreateSection[];
};
