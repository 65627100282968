import { CreateFileRequest } from '../storage/types';
import { FileMimeType } from '@hypercharge/docs/lib/types';

export type DocsConfiguration = {
  docsInternalHandlerLambdaArn: string;
};

type TemplateFile = Omit<CreateFileRequest, 'tenantId' | 'groupId'> & { contentType: FileMimeType };

export type CreateDocTemplateRequest = {
  tenantId: string;
  title: string;
  file: TemplateFile;
  templateId?: string;
  system?: boolean;
  savedBy?: string;
};

export type UpdateDocTemplateRequest = {
  tenantId: string;
  title: string;
  templateId: string;
  savedBy?: string;
  system?: boolean;
  file?: TemplateFile;
};

export type GetDocTemplateRequest = {
  tenantId: string;
  templateId: string;
};

export { FileMimeType };
