import {
  AggregationRequest,
  FacetConditionQuery,
  FilterRequest
} from '@hypercharge/lambda-utils/lib/search/searchTypes';
import { ListTarget, Item } from '@hypercharge/cms/lib/types';
import { DbPurpose } from '../../types';

export {
  AggregationFilter,
  AggregationRequest,
  AggregationResultSet,
  AggregationType,
  FilterRequest,
  ConditionQuery,
  FacetConditionQuery,
  ConditionType,
  FilterOperatorTypes,
  SortOrder
} from '@hypercharge/lambda-utils/lib/search/searchTypes';
export { ValidationError } from '@hypercharge/lambda-utils/lib/errors';

export { DbPurpose } from '../../types';
export { ListTarget, Item, BulkResult } from '@hypercharge/cms/lib/types';
export { PaginatedList } from '@hypercharge/lambda-utils/lib/types';

export type ItemMap = {
  [key: string]: Item;
};

export type GetItemRequest = {
  tenantId?: string;
  definitionId: string;
  entityId: string;
  expanded?: boolean;
  deleted?: boolean;
  dbPurpose?: DbPurpose;
};

export type GetItemsByPropertyValueRequest = {
  tenantId?: string;
  definitionId: string;
  propertyId: string;
  value: string | string[] | number | number[];
  deleted?: boolean;
  dbPurpose?: DbPurpose;
};

export type GetItemsRequest = {
  tenantId?: string;
  definitionId: string;
  entityIds: string[];
  deleted?: boolean;
  dbPurpose?: DbPurpose;
};

export type GetCrossDefinitionItemsRequest = {
  tenantId?: string;
  targets: ListTarget[];
  principalGroups?: string[];
  principalDomains?: string[];
  deleted?: boolean;
  dbPurpose?: DbPurpose;
};

export type GetAllItemsIdsRequest = {
  tenantId?: string;
  definitionId: string;
  dbPurpose?: DbPurpose;
};

export type GetAllItemsRequest = {
  tenantId?: string;
  definitionId: string;
  principalGroups?: string[];
  principalDomains?: string[];
  dbPurpose?: DbPurpose;
};

export type GetPropertyDataHistoryRequest = {
  tenantId?: string;
  definitionId: string;
  entityId: string;
  propertyId: string;
  limit?: number;
  offset?: number;
  beforeModifiedAt?: number;
  dbPurpose?: DbPurpose;
};

export type SearchItemsRequest = {
  tenantId?: string;
  definitionId: string;
  request?: FilterRequest;
  scroll?: boolean;
  scrollId?: string;
  expanded?: boolean;
  principalGroups?: string[];
  principalDomains?: string[];
  searchDefinitionIds?: string[];
  dbPurpose?: DbPurpose;
};

export type SearchAllItemsRequest = {
  tenantId?: string;
  definitionId: string;
  request?: FilterRequest;
  expanded?: boolean;
  principalGroups?: string[];
  principalDomains?: string[];
  searchDefinitionIds?: string[];
  dbPurpose?: DbPurpose;
  maxItems?: number;
};

export type ClearScrollIdRequest = {
  tenantId?: string;
  scrollId: string | string[];
};

export type ImportItemsRequest<T> = {
  tenantId?: string;
  definitionId: string;
  items: T[];
  modifiedBy?: string;
  domainId?: string;
  needPublishEvents?: boolean;
};

export type CreateItemRequest = {
  tenantId?: string;
  definitionId: string;
  item: Item;
  modifiedBy?: string;
  principalGroups?: string[];
  principalDomains?: string[];
  needRecompute?: boolean;
  needReindex?: boolean;
  needPublishEvent?: boolean;
};

export type UpdateItemRequest = CreateItemRequest;

export type DeleteItemRequest = {
  tenantId?: string;
  definitionId: string;
  entityId: string | string[];
  modifiedBy?: string;
  principalGroups?: string[];
  principalDomains?: string[];
  refreshIndex?: boolean;
};

export type RestoreItemRequest = {
  tenantId?: string;
  definitionId: string;
  entityId: string | string[];
  modifiedBy?: string;
  needPublishEvents?: boolean;
};

export type ValidateItemRequest = {
  tenantId?: string;
  definitionId: string;
  item: Item;
  languageCode?: string;
};

export type GetAggregationsRequest = {
  tenantId?: string;
  definitionId: string;
  principalGroups?: string[];
  principalDomains?: string[];
  request: FilterRequest;
  aggregationRequest: AggregationRequest;
  facetsQuery?: FacetConditionQuery;
  trackTotal?: boolean;
  dbPurpose?: DbPurpose;
};
